<template>
  <div class="relative min-h-screen md:flex">
    <SideNavBar />

    <div class="flex-1 p-10 text-2xl">
      <!-- header -->
      <div class="flex justify-between flex-wrap md:flex-nowwrap items-center">
        <h2 class="font-semibold">Manage Gallery</h2>

        <div v-if="!updatePositionMode">
          <button
            @click="navigateToAddAboutGalleryPage"
            class="btn bg-green-400 btn-sm border-none mr-1"
          >
            Add New
          </button>
          <button
            @click="toggleUpdatePositionMode"
            class="btn bg-red-500 btn-sm border-none"
          >
            Update Position
          </button>
        </div>

        <div v-if="updatePositionMode">
          <button
            @click="toggleUpdatePositionMode"
            class="btn btn-sm border-none mr-1"
          >
            Cancel
          </button>
          <button
            @click="doneEditPosition"
            class="btn btn-sm bg-green-400 border-none"
          >
            Done
          </button>
        </div>
      </div>
      <!-- end of header -->

      <!-- content -->
      <div v-if="isLoading" class="loader"></div>

      <div
        v-if="!isLoading"
        class="
          overflow-x-auto
          bg-white
          rounded-lg
          shadow
          overflow-y-auto
          relative
          mt-5
        "
      >
        <!-- start of normal table -->
        <table
          v-if="!updatePositionMode"
          class="
            border-collapse
            table-auto
            w-full
            whitespace-no-wrap
            bg-white
            table-striped
            relative
          "
        >
          <thead>
            <tr class="text-left">
              <th
                class="
                  bg-gray-200
                  sticky
                  top-0
                  border-b border-gray-200
                  px-6
                  py-2
                  text-gray-600
                  font-bold
                  tracking-wider
                  uppercase
                  text-xs
                "
              >
                Image
              </th>
              <th
                class="
                  bg-gray-200
                  sticky
                  top-0
                  border-b border-gray-200
                  px-6
                  py-2
                  text-gray-600
                  font-bold
                  tracking-wider
                  uppercase
                  text-xs
                "
              >
                Last Updated At
              </th>
              <th
                class="
                  bg-gray-200
                  sticky
                  top-0
                  border-b border-gray-200
                  px-6
                  py-2
                  text-gray-600
                  font-bold
                  tracking-wider
                  uppercase
                  text-xs text-center
                "
              >
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="item in aboutGalleryList" :key="item">
              <td class="border-dashed border-t border-gray-200 text-center">
                <img
                  :src="`${$baseFileUrl}/${item.galleryImageSrc}`"
                  class="w-96 max-h-72 object-contain"
                  alt=""
                />
              </td>
              <td class="border-dashed border-t border-gray-200 text-center">
                <span
                  class="text-gray-700 px-6 py-3 flex items-center text-xs"
                  >{{
                    moment(item.updated_at).format(
                      "ddd MMM DD, YYYY [at] hh:mm a"
                    )
                  }}</span
                >
              </td>
              <td class="border-dashed border-t border-gray-200 text-center">
                <button @click="deleteGalleryImage(item.id)">
                  <TrashIcon class="h-8 w-8 text-red-500" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- end of normal table -->

        <!-- start of draggable table -->
        <table
          v-if="updatePositionMode"
          class="
            border-collapse
            table-auto
            w-full
            whitespace-no-wrap
            bg-white
            table-striped
            relative
          "
        >
          <thead>
            <tr class="text-left">
              <th>&nbsp;</th>
              <th
                class="
                  bg-gray-200
                  sticky
                  top-0
                  border-b border-gray-200
                  px-6
                  py-2
                  text-gray-600
                  font-bold
                  tracking-wider
                  uppercase
                  text-xs
                "
              >
                Image
              </th>
              <th
                class="
                  bg-gray-200
                  sticky
                  top-0
                  border-b border-gray-200
                  px-6
                  py-2
                  text-gray-600
                  font-bold
                  tracking-wider
                  uppercase
                  text-xs
                "
              >
                Last Updated At
              </th>
            </tr>
          </thead>

          <draggable
            v-model="aboutGalleryList"
            tag="tbody"
            @start="drag = true"
            @end="drag = false"
          >
            <tr v-for="item in aboutGalleryList" :key="item.id">
              <td scope="row">
                <DotsVerticalIcon class="h-8 w-8 cursor-pointer text-black" />
              </td>
              <td class="border-dashed border-t border-gray-200 text-center">
                <img
                  :src="`${$baseFileUrl}/${item.galleryImageSrc}`"
                  class="w-96 max-h-72"
                  alt=""
                />
              </td>
              <td class="border-dashed border-t border-gray-200 text-center">
                <span
                  class="text-gray-700 px-6 py-3 flex items-center text-xs"
                  >{{
                    moment(item.updated_at).format(
                      "ddd MMM DD, YYYY [at] hh:mm a"
                    )
                  }}</span
                >
              </td>
            </tr>
          </draggable>
        </table>
        <!-- end of draggable table -->
      </div>
      <!-- end of content -->
    </div>
  </div>
</template>

<script>
import SideNavBar from "../../components/SideNavBar.vue";
import { VueDraggableNext } from "vue-draggable-next";

import { TrashIcon, DotsVerticalIcon } from "@heroicons/vue/outline";
import moment from "moment";

export default {
  name: "ManageAboutGallery",
  components: {
    SideNavBar,
    draggable: VueDraggableNext,
    TrashIcon,
    DotsVerticalIcon,
  },
  data() {
    return {
      isLoading: true,
      updatePositionMode: false,
      aboutGalleryList: [],
    };
  },
  mounted() {
    this.moment = moment;
    this.getAboutGallery();
  },
  methods: {
    getAboutGallery() {
      this.axios({
        url: "/aboutGallery/getGalleryImages",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          this.aboutGalleryList = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({
              name: "Login",
              query: {
                error:
                  "Your account has been locked by admin. If you think this is a mistake, please contact your admin. Thank you.",
              },
            });
            this.$store.dispatch("createUserSession", null); // remove user state to prevent user from logging in
          }
        });
    },
    doneEditPosition() {
      this.toggleUpdatePositionMode();
      // change position to new updated position
      for (var i = 0; i < this.aboutGalleryList.length; i++) {
        this.aboutGalleryList[i].galleryImagePosition = i;
      }

      this.axios({
        url: "/aboutGallery/updateGalleryImagePosition",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          aboutGalleryImageList: this.aboutGalleryList,
        },
      }).then((response) => {
        this.showToastDialog(response);
        this.getAboutGallery();
      });
    },
    deleteGalleryImage(galleryImageID) {
      this.$swal
        .fire({
          title: "Are you sure you want to delete this image?",
          confirmButtonColor: "#dc3545",
          confirmButtonText: "Yes",
          showCancelButton: true,
        })
        .then((result) => {
          if (!result.isDismissed) {
            this.axios({
              url: "/aboutGallery/deleteGalleryImage",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: {
                galleryImageID: galleryImageID,
              },
            }).then((response) => {
              this.showToastDialog(response);
              this.getAboutGallery();
            });
          }
        });
    },
    showToastDialog(response) {
      this.$swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        icon: "success",
        title: response.data["message"],
      });
    },
    toggleUpdatePositionMode() {
      this.updatePositionMode = !this.updatePositionMode;
    },
    navigateToAddAboutGalleryPage() {
      this.$router.push({ name: "AddGalleryImage" });
    },
  },
};
</script>

<style>
</style>